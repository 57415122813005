import { UserRole } from "@/types/role";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/Dialog";
import { RadioGroup, RadioGroupItem } from "../ui/RadioGroup";
import { Label } from "../ui/Label";
import {
  AlertCircleIcon,
  Loader2Icon,
  User2Icon,
  Users2Icon,
} from "lucide-react";
import { Button } from "../ui/Button";
import { useEffect, useMemo, useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "../ui/Alert";
import { Badge } from "../ui/Badge";
import { cn } from "@/lib/utils";
import { useTheme } from "../context/ThemeContext";

interface SwitchRoleDialogProps {
  open: boolean;
  activeDealerId: string;
  activeRole: UserRole;
  availableDealers: {
    id: string;
    code: string;
    name: string;
    groupLogoLightUrl: string | null;
    groupLogoDarkUrl: string | null;
    mainRoles: UserRole[];
  }[];
  loading: boolean;
  onSubmit: (dealerId: string, role: UserRole) => void;
  onClose: () => void;
}

const SwitchDealerDialog = ({
  open,
  activeDealerId,
  activeRole,
  availableDealers,
  loading,
  onSubmit,
  onClose,
}: SwitchRoleDialogProps) => {
  const { activeTheme } = useTheme();

  const [error, setError] = useState("");
  const [dealerId, setDealerId] = useState<string>();
  const [role, setRole] = useState<UserRole | null>();

  const sortedAvailableDealers = useMemo(() => {
    const sortedDealers = [...availableDealers];
    const activeDealer = sortedDealers.find(
      (dealer) => dealer.id === activeDealerId,
    );
    if (activeDealer) {
      sortedDealers.splice(sortedDealers.indexOf(activeDealer), 1);
      sortedDealers.unshift(activeDealer);
    }
    return sortedDealers;
  }, [availableDealers, activeDealerId]);

  const getAvailableRoles = (dealerId: string) => {
    return availableDealers.find((d) => d.id === dealerId)?.mainRoles;
  };

  const availableRoles = getAvailableRoles(dealerId ?? "");

  const onChangeDealerId = (id: string) => {
    if (id === dealerId) return;

    setError("");
    setDealerId(id);

    const sameDealer = activeDealerId === id;
    const availableRoles = getAvailableRoles(id);

    if (sameDealer) {
      setRole(activeDealerId === id ? activeRole : null);
    } else if (availableRoles?.length === 1) {
      setRole(availableRoles[0]);
    }
  };

  const onChangeRole = (role: UserRole) => {
    setError("");
    setRole(role);
  };

  const handleSubmit = () => {
    setError("");

    if (!dealerId) {
      return setError("Please select a location");
    }

    if (!role) {
      return setError("Please select a role");
    }

    onSubmit(dealerId, role);
  };

  useEffect(() => {
    setError("");
    setDealerId(activeDealerId);
    setRole(activeRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Switch Location</DialogTitle>
          <DialogDescription>Choose a location to switch to</DialogDescription>
        </DialogHeader>
        {error && (
          <Alert variant="destructive">
            <AlertCircleIcon className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div>
          <div className="flex flex-col gap-2 py-4 pt-0">
            {sortedAvailableDealers.map((dealer) => {
              let logoUrl: string | null = null;

              if (activeTheme === "light") {
                logoUrl = dealer.groupLogoLightUrl || dealer.groupLogoDarkUrl;
              } else if (activeTheme === "dark") {
                logoUrl = dealer.groupLogoDarkUrl || dealer.groupLogoLightUrl;
              }

              return (
                <button
                  type="button"
                  key={dealer.id}
                  className={cn(
                    "flex flex-col items-start gap-2 rounded-lg border-2 p-3 text-left text-sm transition-all hover:bg-accent",
                    dealer.id === dealerId && "border-primary",
                    dealer.id === activeDealerId &&
                      "bg-primary/10 hover:bg-primary/10",
                  )}
                  onClick={() => onChangeDealerId(dealer.id)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex w-full flex-col items-start gap-1">
                      {logoUrl && (
                        <img
                          src={logoUrl}
                          alt="dealer group logo"
                          className="h-5 w-auto"
                        />
                      )}
                      <div className="font-semibold">
                        {dealer.name}
                        {dealer.id === activeDealerId && (
                          <span className="ml-1 text-xs font-normal text-muted-foreground">
                            (Current)
                          </span>
                        )}
                      </div>
                      <div className="text-xs font-medium">{dealer.code}</div>
                    </div>
                  </div>

                  {dealer.mainRoles.length ? (
                    <div className="flex items-center gap-2">
                      {dealer.mainRoles.map((role) => (
                        <Badge
                          key={role}
                          variant="outline"
                          className="capitalize"
                        >
                          {role.replace("_", " ").toLowerCase()}
                        </Badge>
                      ))}
                    </div>
                  ) : null}
                </button>
              );
            })}
          </div>

          {dealerId && (
            <div className="space-y-2">
              <DialogDescription>Choose a role</DialogDescription>
              <RadioGroup
                value={role ?? ""}
                onValueChange={onChangeRole}
                className="grid grid-cols-2 gap-4"
              >
                {availableRoles?.includes(UserRole.SUPER_ADMIN) && (
                  <div>
                    <RadioGroupItem
                      value={UserRole.SUPER_ADMIN}
                      id={`role-${UserRole.SUPER_ADMIN}`}
                      className="peer sr-only"
                      checked={role === UserRole.SUPER_ADMIN}
                    />
                    <Label
                      htmlFor={`role-${UserRole.SUPER_ADMIN}`}
                      className={cn(
                        "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                        activeDealerId === dealerId &&
                          activeRole === UserRole.SUPER_ADMIN &&
                          "bg-primary/10 hover:bg-primary/10",
                      )}
                    >
                      <User2Icon className="h-5 w-5" />
                      Super Admin
                    </Label>
                  </div>
                )}
                {availableRoles?.includes(UserRole.EMPLOYEE) && (
                  <div>
                    <RadioGroupItem
                      value={UserRole.EMPLOYEE}
                      id={`role-${UserRole.EMPLOYEE}`}
                      className="peer sr-only"
                      checked={role === UserRole.EMPLOYEE}
                    />
                    <Label
                      htmlFor={`role-${UserRole.EMPLOYEE}`}
                      className={cn(
                        "flex flex-col items-center justify-between gap-2 rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary",
                        activeDealerId === dealerId &&
                          activeRole === UserRole.EMPLOYEE &&
                          "bg-primary/10 hover:bg-primary/10",
                      )}
                    >
                      <Users2Icon className="h-5 w-5" />
                      Employee
                    </Label>
                  </div>
                )}
              </RadioGroup>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={
              loading || (activeDealerId === dealerId && activeRole === role)
            }
          >
            {loading && <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />}
            Switch
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SwitchDealerDialog;
